import '../App.css';

const NotFound = () => {

    return (
        <div>
            <p>Page not found, please try again</p>
        </div>
    )
}

export default NotFound;