import { axiosInstance } from "../http-common";

const getRdpFile = (guid, useAllMonitors) => {
    const json = JSON.stringify({ "useAllMonitors": useAllMonitors });
    return axiosInstance.post('/RDP?guid=' + guid, json);
}

const rdpService = {
    getRdpFile
}

export default rdpService