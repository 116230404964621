
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import HelpDialog from '../dialogs/HelpDialog';
import ResetGuidDialog from '../dialogs/ResetGuidDialog';

export default function R24Card(props) {
    const { resetGuidVisible, dialogText, avatarIcon, alertMessageText, resetAlert, alertSeverity, children } = props;

    const handleCloseAlert = () => {
        resetAlert();
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Card sx={{ mt: 3 }}>
                <CardContent>
                    <Grid container justifyContent={'center'}>
                        <Typography variant="h5" component='h1'>Remote24 - Shield</Typography>
                    </Grid>
                    <StyledSpan>
                        <Avatar
                            sx={{
                                margin: theme => theme.spacing(1),
                                backgroundColor: theme => theme.palette.secondary.main,
                            }}
                        >
                            {avatarIcon}
                        </Avatar>
                        {children}
                        <Grid sx={{ marginTop: theme => theme.spacing(2), marginBottom: theme => theme.spacing(2), justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: '1rem' }} container>
                            {/* Fix so that helpdialog is dynamic as well */}
                            <div>
                                {
                                    resetGuidVisible &&
                                    <ResetGuidDialog />
                                }
                            </div>
                            <div>
                                <HelpDialog infoText={dialogText} />
                            </div>
                        </Grid>
                    </StyledSpan>
                </CardContent>
            </Card>
            {
                alertMessageText ?
                    <Alert
                        sx={{
                            marginTop: (theme) => theme.spacing(2),
                            position: 'absolute',
                            maxWidth: '19.563rem !important',
                            minWidth: '19.563rem !important'
                        }}
                        severity={alertSeverity}
                        onClick={() => handleCloseAlert()}
                    >
                        {alertMessageText}
                    </Alert>
                    : <></>
            }
        </Container>
    );
}

const StyledSpan = styled('span')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}))