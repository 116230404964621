import React, { createContext, useState, useEffect } from 'react';
import loginService from '../services/login.service';

export const AuthContext = createContext();

export function AuthProvider({ children }) {

  const [guid, setGuid] = useState(null);
  const [guidError, setGuidError] = useState('');
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [authLoading, setLoading] = useState(false);
 
  function resetGuidError () {
    setGuidError('');
  }

  // Check if can log in via stored GUID
  useEffect(() => {
    const storedGUID = localStorage.getItem('r24userguid');
    login(storedGUID);
  }, []);

  function login (guid) {
    if (guid !== null) {
      setLoading(true);
      loginService.login(guid)
        .then(function (response) {
          if (response.status === 200) {
            localStorage.setItem('r24userguid', guid);
            setGuid({ guid });
            setUserLoggedIn(true);
          } else {
            console.log(response.status.toString());
            setGuidError('Felaktigt ID, kontrollera och försök igen.');
            setUserLoggedIn(false);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log('Client auth error: ' + error);
          setGuidError('Något gick fel. Kontakta support om felet kvarstår.');
          setUserLoggedIn(false);
          setLoading(false);
        })
    }
  }

  return (
    <AuthContext.Provider value={{userLoggedIn, guid, login, guidError, resetGuidError, authLoading }}>
      {children}
    </AuthContext.Provider>
  );
}