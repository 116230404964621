import SecurityIcon from '@mui/icons-material/Security';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { AuthContext } from './auth';
import R24Card from './cards/R24Card';

function Login() {
    const [loading, setLoading] = useState(false);
    const [guid, setGuid] = useState('');

    const { login, guidError, resetGuidError, authLoading } = useContext(AuthContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        login(guid);
        setLoading(false);
    }

    const resetAlertError = () => {
        resetGuidError();
    }

    return (
        <R24Card resetGuidVisible={!authLoading} avatarIcon={<SecurityIcon />} alertMessageText={guidError} resetAlert={resetAlertError} alertSeverity={'warning'} >
            { authLoading ?                         
            <Box display="flex" justifyContent="center" >
                <CircularProgress />
            </Box>
                :
            <React.Fragment>
                <Typography component="h1" variant="h5">
                    Identifiera dig
                </Typography>
                <StyledForm
                    noValidate
                    onSubmit={handleSubmit}
                >
                    {
                        loading ?
                            <Box display="flex" justifyContent="center" >
                                <CircularProgress />
                            </Box>
                            :
                            <>
                                <TextField
                                    onChange={e => setGuid(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    value={guid}
                                    fullWidth
                                    name="guid"
                                    label="Ditt unika ID"
                                    type="text"
                                    id="guid"
                                    autoComplete="current-guid"
                                />
                            </>
                    }
                    <Button
                        margin='normal'
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{ margin: theme => theme.spacing(3, 0, 2) }}
                        disabled={loading || !login || !guid}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Verifiera ID
                    </Button>
                </StyledForm>
            </React.Fragment>
            }
        </R24Card>
    )
}

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
}));

export default Login;


