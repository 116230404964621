import SecurityIcon from '@mui/icons-material/Security';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import 'dayjs/locale/se';
import { useContext, useState } from 'react';
import whitelistService from '../services/whitelist.service';
import rdpService from '../services/rdp.service';
import { AuthContext } from './auth';
import R24Card from './cards/R24Card';

const Home = () => {
    const { guid } = useContext(AuthContext);

    const helpText = 'Du ges åtkomst till Remote24:s tjänster från att du klickar på "Lås upp". Om det inte fungerar inom fem minuter, kontakta supporten.';
    const [loading, setLoading] = useState(false);

    const [ipMessage, setIpMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState('');

    const postPublicIP = () => {
        setLoading(true);
        try {
            whitelistService.postIP(guid.guid)
                .then(function (response) {
                    if (response.status === 200) {
                        var date = new Date();
                        date.setDate(date.getDate() + 7);

                        setIpMessage('Upplåsningen lyckades');
                        setAlertSeverity('success');
                        setLoading(false);
                    } else {
                        setIpMessage('Något gick fel, försök igen');
                        setAlertSeverity('warning');
                        setLoading(false);
                    }
                })
                .catch(function (error) {
                    setIpMessage('Något gick fel: ' + error);
                    setAlertSeverity('warning');
                    setLoading(false);
                })
        } catch (error) {
            console.log(error);
            setIpMessage('Något gick fel, försök igen');
            setAlertSeverity('warning');
            setLoading(false);
        }
    }

    const downloadRdp = () => {
        setLoading(true);
        try {

            rdpService.getRdpFile(guid.guid, true)
                .then(function (response) {
                    if (response.status === 200) {
                        //Create a Blob from the PDF Stream
                     const file = new Blob([response.data], { type: "application/rdp" });
                        //Build a URL from the file
                        const href  = URL.createObjectURL(file);
                        // create "a" HTML element with href to file & click
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'R24CloudServer.rdp'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                        setLoading(false);
                    } else {
                        setIpMessage('Något gick fel, försök igen');
                        setAlertSeverity('warning');
                        setLoading(false);
                    }
                })
                .catch(function (error) {
                    setIpMessage('Något gick fel: ' + error);
                    setAlertSeverity('warning');
                    setLoading(false);
                })
        } catch (error) {
            console.log(error);
            setIpMessage('Något gick fel, försök igen');
            setAlertSeverity('warning');
            setLoading(false);
        }
    }

    return (
        <R24Card resetGuidVisible={false} dialogText={helpText} avatarIcon={<SecurityIcon />} alertMessageText={ipMessage} resetAlert={() => setIpMessage()} alertSeverity={alertSeverity}>
            <StyledForm noValidate>
                {
                    loading ?
                        <Box display="flex" justifyContent="center"
                            sx={{
                                width: '17.333rem !important',
                                eight: '2.281rem !important'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                        :
                        <>
                            <Button
                                onClick={() => postPublicIP()}
                                fullWidth
                                disabled={loading}
                                variant="contained"
                                color="secondary"
                                sx={{
                                    margin: theme => theme.spacing(3, 0, 2),
                                    width: '17.333rem !important',
                                    height: '2.281rem !important'
                                }}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Lås upp
                            </Button>
                            <Button
                                onClick={() => downloadRdp()}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                sx={{
                                    margin: theme => theme.spacing(3, 0, 2),
                                    width: '17.333rem !important',
                                    height: '2.281rem !important'
                                }}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Ladda hem RDP-fil
                            </Button>                        
                        </>
                }
            </StyledForm>
        </R24Card>
    )
}

const StyledForm = styled('form')(({ theme }) => ({
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
}));

export default Home;

