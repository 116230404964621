import { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthContext } from './components/auth';
import Home from './components/Home';
import Login from './components/Login';
import NotFound from './components/NotFound';

function App() {

  const { userLoggedIn } = useContext(AuthContext);

  return (
      <Router>
        <div className="App">
          <div className='Container'>
            <Routes>
              <Route exact path='/' element={userLoggedIn ? <Home /> : <Login />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
  );
}

export default App;
