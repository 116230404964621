import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import loginService from '../../services/login.service';

export default function ResetGuidDialog(props) {
    const { okButtonOverride = 'Stäng', sendButtonOverride = 'Skicka', infoText = 'Skriv in din epost för att efterfråga ett nytt unikt ID. Om du finns i vår databas kommer det ett mail till dig inom kort.', titleText = 'Efterfråga nytt unikt ID' } = props;

    const [open, setOpen] = React.useState(false);
    const [userEmail, setUserEmail] = React.useState('');

    const tryResetGuid = () => {
        try {
            loginService.tryGuidReset(userEmail)
                .then(function (response) {
                    if (response.status === 200) {
                        alert('ID har skickats till din epost');
                    } else {
                        alert('Något gick fel. Försök igen senare, eller kontakta support.');
                    }
                    setUserEmail('');
                    handleClose();
                })
        } catch (error) {
            console.log('ERROR: ');
            console.log(error);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} variant='contained'>
                Glömt ID
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {titleText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {infoText}
                    </DialogContentText>
                    <TextField
                        onChange={e => setUserEmail(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="guid"
                        label="Din epost"
                        type="text"
                        id="guid"
                        value={userEmail}
                    />
                </DialogContent>
                <DialogActions>
                    <Button style={{ color: 'grey' }} onClick={() => tryResetGuid()} autoFocus>{sendButtonOverride}</Button>
                    <Button style={{ color: 'grey' }} onClick={handleClose} autoFocus>{okButtonOverride}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}