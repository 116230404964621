import Axios from "axios";
const envSettings = window;

export const axiosInstance = Axios.create({
    baseURL: envSettings.API_URL,
    headers: {
        'Accept-Language': 'se',
        'content-type': 'application/json'
    }
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = { ...config.headers };
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (error?.response?.status === 401) {
        localStorage.clear();
        console.log("ERROR 401");
        return Promise.reject(error);
    }
    return error.response;
});