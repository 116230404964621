import { axiosInstance } from "../http-common";

const postIP = (guid) => {
    return axiosInstance.post('whitelistedip?requestSource=REACT&userLoginGuid=' + guid);
}

const whitelistService = {
    postIP
}

export default whitelistService