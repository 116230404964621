import { axiosInstance } from "../http-common";

const login = (guid) => {
    return axiosInstance.post('user?guid=' + guid);
}

const tryGuidReset = (email) => {
    return axiosInstance.post('user/ResetGuid?email=' + email);
}

const loginService = {
    login,
    tryGuidReset
}

export default loginService;